<template>
  <div ref="practice-view" class="col responsive-tabs">
    <!-- tabbed nav for desktop hidden on mobile view -->
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'practice-overview' }"
          style="cursor: pointer"
          @click="setTab('practice-overview')"
          >Overview</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'licensing-links' }"
          style="cursor: pointer"
          @click="setTab('licensing-links')"
          >Licensing</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'credentialing-links' }"
          style="cursor: pointer"
          @click="setTab('credentialing-links')"
          >Credentials</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'grant-writing-links' }"
          style="cursor: pointer"
          @click="setTab('grant-writing-links')"
          >Grants</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'pro-supervision' }"
          style="cursor: pointer"
          @click="setTab('pro-supervision')"
          >Supervision</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'pro-contacts' }"
          style="cursor: pointer"
          @click="setTab('pro-contacts')"
          >Contacts</a
        >
      </li>
    </ul>
    <!-- Accordion items - headers hidden on desktop view-->
    <div id="content" class="tab-content mb-5">
      <!-- Overview -->
      <div
        id="overview"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'practice-overview' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'practice-overview' }"
              style="cursor: pointer"
              @click="setTab('practice-overview')"
              >Overview</a
            >
          </h5>
        </div>
        <practice-overview v-if="currTab == 'practice-overview'" />
      </div>
      <!-- Licensing -->
      <div
        id="pane-B"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'licensing-links' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'licensing-links' }"
              style="cursor: pointer"
              @click="setTab('licensing-links')"
              >Licensing</a
            >
          </h5>
        </div>
        <licensing-links v-if="currTab == 'licensing-links'" />
      </div>
      <!-- Credentials -->
      <div
        id="credentials"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'credentialing-links' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'credentialing-links' }"
              style="cursor: pointer"
              @click="setTab('credentialing-links')"
              >Credentials</a
            >
          </h5>
        </div>
        <credentialing-links v-if="currTab == 'credentialing-links'" />
      </div>
      <!-- Grants -->
      <div
        id="grants"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'grant-writing-links' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'grant-writing-links' }"
              style="cursor: pointer"
              @click="setTab('grant-writing-links')"
              >Grants</a
            >
          </h5>
        </div>
        <grant-writing-links v-if="currTab == 'grant-writing-links'" />
      </div>
      <!-- supervisor -->
      <div
        id="supervision"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'pro-supervision' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'pro-supervision' }"
              style="cursor: pointer"
              @click="setTab('pro-supervision')"
              >Supervision</a
            >
          </h5>
        </div>
        <pro-supervision v-if="currTab == 'pro-supervision'" />
      </div>
      <!-- Contacts -->
      <div
        id="contacts"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'pro-contacts' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'pro-contacts' }"
              style="cursor: pointer"
              @click="setTab('pro-contacts')"
              >Contacts</a
            >
          </h5>
        </div>
        <pro-contacts
          v-if="currTab == 'pro-contacts'"
          @clean="isDirty = false"
          @dirty="isDirty = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PracticeOverview from './PracticeOverview.vue';
import LicensingLinks from './LicensingLinks.vue';
import CredentialingLinks from './CredentialingLinks.vue';
import GrantWritingLinks from './GrantWritingLinks.vue';
import ProSupervision from './pro-supervision/ProSupervision.vue';
import ProContacts from './pro-contacts/ProContacts.vue';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import { ref, onMounted, onBeforeMount, onBeforeUnmount } from 'vue';

export default {
  components: {
    PracticeOverview,
    LicensingLinks,
    CredentialingLinks,
    GrantWritingLinks,
    ProSupervision,
    ProContacts,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const currTab = ref('practice-overview');

    const setTab = async (tabName) => {
      if (isDirty.value) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;

      currTab.value = tabName;
      router.replace({ query: { tab: tabName } });
    };

    const practiceView = ref(null);

    onMounted(() => {
      if (route.query && route.query.tab) {
        currTab.value = route.query.tab;
      } else {
        currTab.value = 'practice-overview';
      }

      window.scrollTo(0, practiceView.value.offsetTop);
    });

    const isDirty = ref(false);

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    const store = useStore();
    // checks for unsaved changes before intra-app routing
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      next();
    });

    return {
      currTab,
      setTab,
      isDirty,
      'practice-view': practiceView,
    };
  },
};
</script>
