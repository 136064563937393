<template>
  <div>
    <div
      v-if="isShown"
      class="alert alert-info d-flex align-items-center alert-dismissible fade"
      :class="{ 'show active': isShown == true }"
    >
      <i :class="`pe-3 fas fa-${icon} fa-4x`"></i>
      <span>
        <slot name="message"></slot>
      </span>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="close"
      ></button>
    </div>
  </div>
</template>

<script>
import { ref, toRef, watch, onMounted } from 'vue';
export default {
  props: {
    icon: {
      type: String,
      default: 'exclamation-triangle',
    },
    shown: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const isShown = ref(true);
    const close = () => {
      isShown.value = false;
    };
    onMounted(() => {
      if (props.shown) {
        isShown.value = true;
      }
      if (props.shown == false) {
        isShown.value = false;
      }
    });
    watch(toRef(props, 'shown'), (newvalue) => {
      isShown.value = newvalue;
    });

    return {
      isShown,
      close,
    };
  },
};
</script>
