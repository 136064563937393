<template>
    <div ref="pro-home-container" class="container py-md-4 position-relative">
      <div class="row">
        <!-- Professional Bio -->
        <!-- <div class="col-12 col-md-4 col-lg-3 col-xl-2 mb-4 mb-md-0 ">
          <div class="row align-items-center">
            <div class="col-6 col-md-12">
              <img class="img-fluid mt-md-2" :src="profilePicSrc">
            </div>
            <div class="col px-md-4">
              <h2 class="h5 my-md-4">{{ $store.getters.fullName }}</h2>
              <p v-if="bio" class="Lead"><b>Bio:</b> {{ bio }}</p>
            </div>
          </div>
        </div> -->
        <my-care v-if="$route.query.view == 'my-care'" />
        <!-- <credentialing-view v-else-if="$route.query.view == 'credentialing'" />
        <licensing-view v-else-if="$route.query.view == 'licensing'" /> -->
        <!-- <div v-else class="col">
          <div class="row row-cols-2 row-cols-md-3 row-cols-lg-5 align-items-center">
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=84&lessonId=292" >8&nbsp;Dimensions</a></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=82&lessonId=295" >Apprentice</a></div>
            <div class="col p-2 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=87&lessonId=293" >Beehive</a></div>
            <div class="col p-2 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=71&lessonId=287" >BHU</a></div>

            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=88&lessonId=297" >Community</a></div>
            
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-outline-primary" type="button" @click="$router.replace({ name: 'pro-home', query: { view: 'credentialing' } })">Credentialing</button></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=83&lessonId=301" >Education</a></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=108&lessonId=382" >EHR</a></div> -->
            <!-- Coming Soon -->
            <!-- <div class="col p-2 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=71&lessonId=288" >FAQ's</a></div>
            <tooltip-outline-link display-text="Fax/Sign" :container-classes="['col', 'p-2', 'px-md-2', 'py-lg-3', 'text-center', 'd-grid']" />
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=78&lessonId=303" >Forms</a></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=76&lessonId=304" >Grants</a></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=91&lessonId=305" >Insurance</a></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-outline-primary" type="button" @click="$router.replace({ name: 'pro-home', query: { view: 'licensing' } })">Licensure</button></div>
            <div class="col p-2 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=93&lessonId=308" >Manage Practice</a></div>
            <tooltip-outline-link display-text="Master Class" :container-classes="['col', 'p-2', 'px-md-2', 'py-lg-3', 'text-center', 'd-grid']" />
            <tooltip-outline-link display-text="My Files" :container-classes="['col', 'p-2', 'px-md-2', 'py-lg-3', 'text-center', 'd-grid']" />
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/Course/Start?courseID=81&lessonId=309" >Practitioners</a></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://bhealthy.cloudu.com/" >Resources</a></div>

            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://www.therapyportal.com/p/bhealthyforlife/" >Telehealth</a></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-outline-primary" href="https://www.therapyportal.com/p/bhealthyforlife/" >Treatment Plan</a></div> -->

            <!-- Coming Soon -->

          <!-- </div>
        </div>-->
      </div>

    </div> 
</template>

<script>
import { computed, onMounted, ref, inject } from 'vue'
import { useStore } from 'vuex'
import MyCare from './MyCare.vue'
// import CredentialingView from './CredentialingView.vue'
// import LicensingView from './LicensingView.vue'
// import TooltipOutlineLink from '@/views/bh2/shared/components/TooltipOutlineLink.vue'

export default {
    components: {
        MyCare,
      // CredentialingView,
      // LicensingView,
      // TooltipOutlineLink
    },
    setup() {
        const store = useStore()
        const userApi = inject('api').userApi()
        const loading = inject('$loading')

        const proHomeContainer = ref(null)
        const bio = ref('')
        onMounted(async () => {
            const loader = loading.show({
                container: proHomeContainer.value,
                isFullPage: false
            })
            const response = await userApi.getProfileInfo(store.getters.userType)
            bio.value = response.data.biography || ''
            if (bio.value.length > 50) {
                bio.value = bio.value.slice(0, 50) + '...'
            }
            loader.hide()
        })


        const profilePicSrc = computed(() => {
            return store.getters.userId
                ? `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${store.getters.userId}`
                : null;
        }
        );

        return {
            profilePicSrc,
            'pro-home-container': proHomeContainer,
            bio
        }
    },
}
</script>

<style scoped>
.comb {
    background: url(https://static.wixstatic.com/media/04d070_477410a44b354136839a55a223cbba3a~mv2.png/v1/fill/w_678,h_710,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Honeycomb%20Isolated.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.comb .btn-outline-primary {
    background-color: white;
}

.comb .btn-outline-primary:hover,
.comb .btn-outline-primary.active {
    background-color: #008b8b;
    background-image: var(--bs-gradient);
    color: white;
}
</style>