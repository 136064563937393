<template>
  <div id="collapse-C" class="collapse show">
    <div ref="manage-patients-container" class="card-body position-relative">
      <div class="row">
        <div class="col-12 col-md-7 col-lg-8 col-xl-9">
          <div
            class="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2 row-cols-xl-3 g-4"
          >
            <div v-for="patient in patients" :key="patient.id" class="col">
              <div
                class="card h-100"
                :style="{ opacity: patient.isEnabled ? 1 : 0.5 }"
              >
                <div class="row g-0">
                  <div class="col-4">
                    <a class="stretched-link">
                      <img
                        :src="getProfPicUrl(patient)"
                        class="img-fluid d-block mx-auto"
                        alt="..."
                    /></a>
                  </div>
                  <div class="col-8">
                    <div class="card-body p-2">
                      <h5 class="card-title mb-0">
                        {{ patient.lastName + ', ' + patient.firstNames }}
                      </h5>
                      <span class="small"
                        ><b class="text-muted">Last Login:</b>
                        {{ convertToLocalTime(patient.lastLoginUtc) }}</span
                      >
                      <div class="m-1 form-check">
                        <input
                          :id="`is-enabled-${patient.id}`"
                          v-model="patient.isEnabled"
                          style="z-index: 20; position: relative"
                          class="form-check-input"
                          type="checkbox"
                          @change="updatePatientStatus(patient)"
                        />
                        <label
                          class="form-check-label"
                          :for="`is-enabled-${patient.id}`"
                          >Active</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <tbl-page-seln
            v-model="pgNum"
            class="mt-3"
            :total-pages="totalPages"
            @updated="refresh"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import TblPageSeln from '../../shared/components/TblPageSeln.vue';
import stdLocalTime from '../../../../services/lib/localTime';

export default {
  components: {
    TblPageSeln,
  },
  setup() {
    const profApi = inject('api').profApi();
    const loading = inject('$loading');

    onMounted(() => {
      refresh();
    });

    const patients = ref([]);
    const managePatientsContainer = ref(null);
    const pgNum = ref(1);
    const pgSz = ref(9);
    const totalPages = ref(0);

    const refresh = async () => {
      const loader = loading.show({
        container: managePatientsContainer.value,
        isFullPage: false,
      });
      const response = await profApi.getManagedPatients(
        pgSz.value,
        pgNum.value - 1
      );
      patients.value = response.data.data;
      totalPages.value = response.data.totalPages;
      loader.hide();
    };

    const updatePatientStatus = async (patient) => {
      const loader = loading.show({
        container: managePatientsContainer.value,
        isFullPage: false,
      });
      await profApi.setPatientAcctStatus(patient.id, patient.isEnabled);
      loader.hide();
    };

    const getProfPicUrl = (patient) => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${patient.id}`;
    };

    const convertToLocalTime = (utcTime) => {
      if (!utcTime) {
        return 'Never';
      }
      return stdLocalTime(utcTime, true);
    };

    return {
      refresh,
      'manage-patients-container': managePatientsContainer,
      patients,
      pgNum,
      totalPages,
      updatePatientStatus,
      getProfPicUrl,
      convertToLocalTime,
    };
  },
};
</script>
