<template>
  <!-- start tab section -->
  <div ref="patients-view" class="col responsive-tabs">
    <!-- tabbed nav for desktop hidden on mobile view -->
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'my-patients' }"
          style="cursor: pointer"
          @click="setTab('my-patients')"
          >My Patients</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'manage-patients' }"
          style="cursor: pointer"
          @click="setTab('manage-patients')"
          >Manage Patients</a
        >
      </li>
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'goals-actions' }"
          style="cursor: pointer"
          @click="setTab('goals-actions')"
          >Goal Sets &amp; Actions</a
        >
      </li> -->
    </ul>
    <!-- Accordion items - headers hidden on desktop view-->
    <div id="content" class="tab-content mb-5">
      <!-- tab one -->
      <div
        id="my-patients"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'my-patients' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'my-patients' }"
              style="cursor: pointer"
              @click="setTab('my-patients')"
              >My Patients</a
            >
          </h5>
        </div>
        <my-patients v-if="currTab == 'my-patients'"></my-patients>
      </div>
      <!-- tab two -->
      <div
        id="manage-patients"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'manage-patients' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'manage-patients' }"
              style="cursor: pointer"
              @click="setTab('manage-patients')"
              >Manage Patients</a
            >
          </h5>
        </div>
        <manage-patients v-if="currTab == 'manage-patients'"></manage-patients>
      </div>
      <!-- tab three -->
      <!-- <div
        id="goals-actions"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'goals-actions' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'goals-actions' }"
              style="cursor: pointer"
              @click="setTab('goals-actions')"
              >Goal Sets &amp; Actions</a
            >
          </h5>
        </div>
        <goals-actions
          v-if="currTab == 'goals-actions'"
          @dirty="isDirty = true"
          @clean="isDirty = false"
        ></goals-actions>
      </div> -->
    </div>
  </div>
</template>

<script>
import MyPatients from './MyPatients.vue';
import ManagePatients from './ManagePatients.vue';
// import GoalsActions from './goals-actions/GoalsActions.vue';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import { ref, onMounted, onBeforeMount, onBeforeUnmount } from 'vue';

export default {
  components: {
    MyPatients,
    ManagePatients,
    // GoalsActions,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const currTab = ref('my-patients');
    const setTab = async (tabName) => {
      if (isDirty.value) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;

      currTab.value = tabName;
      router.replace({ query: { tab: tabName } });
    };

    const patientsView = ref(null);

    onMounted(() => {
      if (route.query && route.query.tab) {
        currTab.value = route.query.tab;
      } else {
        currTab.value = 'my-patients';
      }

      window.scrollTo(0, patientsView.value.offsetTop);
    });

    const isDirty = ref(false);

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    const store = useStore();
    // checks for unsaved changes before intra-app routing
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      next();
    });

    return {
      currTab,
      setTab,
      isDirty,
      'patients-view': patientsView,
    };
  },
};
</script>
