<template>
    <div class="container mb-8">
        <div class="row  align-items-center mb-4 ">
            <pro-menu-large v-if="isLargeScreen"></pro-menu-large>
            <pro-menu-small v-else></pro-menu-small>
                <!-- <div class="container mb-4">
      <div class="row row-cols-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-6 align-items-center mb-3"> -->


        <!-- <div class="col p-2 text-center d-grid"><button class="btn btn-primary" type="button" :disabled="$route.name == 'pro-home' && $route.query.view != 'my-care' && $route.query.view != 'licensing' && $route.query.view != 'credentialing'" @click="$router.push({ name: 'pro-home' })">Home</button></div>
        <div class="col p-2 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'pro-home', query: { 'view': 'my-care' } })">Self Care</button></div>
        <div class="col p-2 text-center d-grid"><a class="btn btn-primary" href="https://bhealthyforlife.com" >BHFL.com</a></div>
        <div class="col p-2 text-center d-grid"><a class="btn btn-primary" href="https://www.recovery4lifellc.com" >RFL.com</a></div> -->
        <!-- Coming Soon -->
        <!-- <tooltip-link display-text="Emergency" title-text="Emergency Resources" href="https://www.recovery4lifellc.com/emergency-resources"  :container-classes="['col', 'p-2', 'text-center', 'd-grid']"  :button-classes="['btn', 'btn-danger']" /> -->
        <!-- Coming Soon -->
        <!-- <tooltip-link display-text="My Cart" :container-classes="['col', 'p-2', 'text-center', 'd-grid']" /> -->
        <!-- Coming Soon -->
        <!-- <div class="col p-2 text-center d-grid"><a class="btn btn-primary" href="https://www.therapyportal.com/p/bhealthyforlife/" >TherapyNotes</a></div>
        <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'patients-view' })">Patients</button></div>
        <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'todo-calendar' })">Schedule</button></div>
        <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'pro-msg' })">Messages</button></div>

        <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'supervision' })">Supervision</button></div> -->
        
        <!-- Coming Soon -->
        <!-- <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'contacts' })">Contacts</button></div>
        <div class="col p-2 text-center d-grid"><button class="btn btn-primary" type="button" @click="logout">Log out</button></div> -->
      </div>
          <router-view></router-view>
    </div>
</template>

<script>
import { inject } from 'vue'
import { useStore } from 'vuex'
//import TooltipLink from '@/views/bh2/shared/components/TooltipLink.vue'
import  ProMenuLarge  from '../menus/ProMenuLarge.vue'
import  ProMenuSmall  from '../menus/ProMenuSmall.vue'
import authLib from '@/services/lib/auth0';
import {useMediaQuery} from '@vueuse/core'

export default {
    components: {
       // TooltipLink
       ProMenuLarge,
       ProMenuSmall
    },
    setup() {
        const api = inject('api')
        const store = useStore()
        const isLargeScreen  = useMediaQuery('(min-width: 768px)').value;
        const is_sso_mode = process.env.VUE_APP_LOGIN_SSO == 'true';
        const logout = async () => {
            store.dispatch('logOut');
            api.setAuth(null);
            if (is_sso_mode) {
                await authLib.doLogout();
            }
        };

        return {
            logout,
            isLargeScreen
        }
    },
}
</script>

