<template>
  <div class="p-3">
    <h3>Need Help?</h3>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam molestias
      perferendis eum tempore veniam aspernatur.
    </p>
  </div>
  <form>
    <div class="form-floating mb-2">
      <textarea
        id="Textarea"
        v-model="body"
        class="form-control"
        placeholder="How can we help you?"
      ></textarea>
      <label for="Textarea">How can we help you?</label>
    </div>
    <div class="text-end">
      <a
        :href="`mailto:${type}@email.com?body=${body}`"
        class="btn btn-secondary btn-sm"
        >Send</a
      >
    </div>
  </form>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    type: {
      type: String,
      default: 'credentialing',
    },
  },
  setup() {
    const body = ref('');

    return {
      body,
    };
  },
};
</script>
