<template>
  <div id="collapse-A" class="collapse show">
    <div class="card-body">
      <div class="row">
        <div class="col-xxl-10">
          <h3>To-Do List</h3>
          <my-todos />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyTodos from '../../todos/MyTodos.vue';

export default {
  components: {
    MyTodos,
  },
  setup() {},
};
</script>
