<template>
    <div id="mycarepro" class="col">
        <div class="row row-cols-2 row-cols-md-3 row-cols-lg-5 d-flex justify-content-center">
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-outline-primary" type="button" @click="$router.push({ name: 'pro-journal', query: { myCare: true } })">Journal</button></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-outline-primary" type="button" @click="$router.push({ name: 'pro-contracts' })">Contracts</button></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-outline-primary" type="button" @click="$router.push({ name: 'pro-diversions' })">Coping Skills</button></div>
            <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-outline-primary" type="button" @click="$router.push({ name: 'pro-lifeplan' })">Life Plan</button></div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default({
    setup() {
        const targetId = ref('mycarepro')
        const scrollToElement = () =>  {
            const el = document.getElementById(targetId.value);

                if (el) {
                    el.scrollIntoView();
                }
            }
            onMounted(async () => {
            scrollToElement();
        })
    },
})
</script>

<style scoped>
.comb {
    background: url(https://static.wixstatic.com/media/04d070_477410a44b354136839a55a223cbba3a~mv2.png/v1/fill/w_678,h_710,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Honeycomb%20Isolated.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.comb .btn-outline-primary {
    background-color: white !important;
}

.comb .btn-outline-primary:hover,
.comb .btn-outline-primary.active {
    background-color: #008b8b;
    background-image: var(--bs-gradient);
    color: white;
}

.btn-outline-primary{
background-color:white !important;
font-weight: 700;
}
</style>