<template>
  <div id="collapse-A" class="collapse show">
    <div ref="my-patients-container" class="card-body position-relative">
      <div class="row">
        <!-- column one -->
        <div class="col">
          <!-- find patient -->
          <form class="mb-3" @submit.prevent="refresh">
            <div class="input-group">
              <div class="form-floating form-floating-group flex-grow-1">
                <input
                  id="find-patient-input"
                  v-model.trim="filter"
                  type="text"
                  class="form-control"
                  name="searchPatient"
                  placeholder="Find Patient"
                />
                <label for="searchPatient">Find a Patient</label>
              </div>
              <button
                id="search-patients-btn"
                type="submit"
                class="input-group-text btn-sm btn-primary px-3"
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
          </form>

          <!-- invite patient -->
          <button
            ref="open-invite-btn"
            type="button"
            class="btn btn-sm btn-sky mb-3 w-100"
            data-bs-toggle="collapse"
            data-bs-target="#newPatient"
            @click="showalert = false"
          >
            Invite A New Patient
          </button>
          <div id="newPatient" class="collapse shadow p-3 mb-4">
            <form @submit.prevent="invitePatient">
              <div class="form-floating mb-3">
                <input
                  id="firstName"
                  v-model="newPatient.firstNames"
                  type="Text"
                  :class="{
                    'form-control': true,
                    'is-invalid': !firstNameChk,
                  }"
                  placeholder="First Name"
                  @input="firstNameChk = true"
                />
                <label for="firstName">First Name</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  id="lastName"
                  v-model="newPatient.lastName"
                  type="Text"
                  :class="{
                    'form-control': true,
                    'is-invalid': !lastNameChk,
                  }"
                  placeholder="Last Name"
                  @input="lastNameChk = true"
                />
                <label for="lastName">Last Name</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  id="email"
                  v-model="newPatient.email"
                  type="email"
                  :class="{
                    'form-control': true,
                    'is-invalid': !emailChk,
                  }"
                  placeholder="Email Address"
                  @input="emailChk = true"
                />
                <label for="email">Email Address</label>
              </div>
              <!-- <div class="form-floating mb-3">
                <input
                  id="phone"
                  type="phone"
                  class="form-control"
                  placeholder="Phone Number"
                />
                <label for="phone">Phone Number</label>
              </div> -->
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-toggle="collapse"
                  data-bs-target="#newPatient"
                  @click="cancelInvite"
                >
                  Cancel
                </button>
                <button class="btn btn-success">Submit</button>
              </div>
            </form>
          </div>
          <system-alert :shown="showalert" icon="check-circle">
            <template #message> Invite successfully sent </template>
          </system-alert>
          <div v-if="showerr" class="alert alert-danger" role="alert">
            {{ showerr }}
          </div>
        </div>

        <div class="col-12 col-md-7 col-lg-8 col-xl-9">
          <div
            class="
              row
              row-cols-1
              row-cols-sm-2
              row-cols-md-1
              row-cols-lg-2
              row-cols-xl-3
              g-4
            "
          >
            <div
              v-for="patient in patientList"
              :id="`patient-details-btn-${patient.patientId}`"
              :key="patient.patientId"
              class="col"
              style="cursor: pointer"
              @click="
                $router.push({
                  name: 'patient-details',
                  params: { pid: patient.patientId },
                })
              "
            >
              <div class="card h-100">
                <div class="row g-0">
                  <div class="col-4">
                    <a class="stretched-link">
                      <img
                        :src="getProfPicUrl(patient)"
                        class="img-fluid d-block mx-auto"
                        alt="..."
                    /></a>
                  </div>
                  <div class="col-8">
                    <div class="card-body p-2">
                      <h5 class="card-title mb-0">
                        {{ patient.fullName }}
                      </h5>
                      <span class="small"
                        ><b class="text-muted">Last Login:</b>
                        {{ convertToLocalTime(patient.lastLoginUtc) }}</span
                      >
                      <div class="card-text small">
                        <ul class="small lh-sm list-group list-group-flush">
                          <li
                            class="
                              list-group-item
                              d-flex
                              justify-content-between
                              align-items-start
                            "
                          >
                            Messages
                            <span class="badge bg-secondary">{{
                              patient.mail
                            }}</span>
                          </li>
                          <li
                            class="
                              list-group-item
                              d-flex
                              justify-content-between
                              align-items-start
                            "
                          >
                            Diversions
                            <span class="badge bg-danger">{{
                              patient.diversions
                            }}</span>
                          </li>
                          <li
                            class="
                              list-group-item
                              d-flex
                              justify-content-between
                              align-items-start
                            "
                          >
                            Journal
                            <span class="badge bg-primary">{{
                              patient.journals
                            }}</span>
                          </li>
                          <li
                            class="
                              list-group-item
                              d-flex
                              justify-content-between
                              align-items-start
                            "
                          >
                            Goals
                            <span class="badge bg-gold">{{
                              patient.goals
                            }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <tbl-page-seln
            v-model="pgNum"
            class="mt-3"
            :total-pages="totalPages"
            @updated="refresh"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import TblPageSeln from '../../shared/components/TblPageSeln.vue';
import stdLocalTime from '../../../../services/lib/localTime';
import SystemAlert from '../../shared/components/SystemAlert.vue';

export default {
  components: {
    TblPageSeln,
    SystemAlert,
  },
  setup() {
    const profApi = inject('api').profApi();
    const loading = inject('$loading');
    const newPatient = ref({});
    const firstNameChk = ref(true);
    const lastNameChk = ref(true);
    const emailChk = ref(true);
    const isValid = ref(null);
    const showalert = ref(false);
    const showerr = ref('');
    onMounted(() => {
      refresh();
    });

    const pgNum = ref(1);
    const pgSz = ref(9);
    const filter = ref('');
    const totalPages = ref(0);
    const myPatientsContainer = ref(null);
    const patientList = ref([]);
    const refresh = async () => {
      const loader = loading.show({
        container: myPatientsContainer.value,
        isFullPage: false,
      });
      const response = await profApi.getPatientsOverview(
        pgNum.value - 1,
        pgSz.value,
        filter.value
      );
      patientList.value = response.data.data;
      totalPages.value = response.data.totalPages;
      loader.hide();
    };

    const getProfPicUrl = (patient) => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${patient.patientId}`;
    };

    const convertToLocalTime = (utcTime) => {
      if (!utcTime) {
        return 'Never';
      }
      return stdLocalTime(utcTime, true);
    };
    const validateForm = () => {
      firstNameChk.value =
        newPatient.value &&
        newPatient.value.firstNames &&
        newPatient.value.firstNames.length > 0;
      lastNameChk.value =
        newPatient.value &&
        newPatient.value.lastName &&
        newPatient.value.lastName.length > 0;
      emailChk.value =
        newPatient.value &&
        newPatient.value.email &&
        newPatient.value.email.length > 0;
      isValid.value = firstNameChk.value && lastNameChk.value && emailChk.value;
    };

    const openInviteBtn = ref(null);
    const invitePatient = async () => {
      //reset error/success messages for previous form submissions
      showalert.value = false;
      showerr.value = '';

      validateForm();
      if (!isValid.value) return;
      const loader = loading.show({
        container: myPatientsContainer.value,
        isFullPage: false,
      });
      try {
        await profApi.invitePatient(newPatient.value);
        newPatient.value = {};
        openInviteBtn.value.click();
        showalert.value = true;
      } catch (error) {
        showerr.value = error.data.detail;
      }
      loader.hide();
    };
    const cancelInvite = async () => {
      newPatient.value = {};
      firstNameChk.value = true;
      lastNameChk.value = true;
      emailChk.value = true;
      showerr.value = '';
    };
    return {
      'my-patients-container': myPatientsContainer,
      patientList,
      getProfPicUrl,
      convertToLocalTime,
      pgNum,
      pgSz,
      filter,
      totalPages,
      refresh,
      newPatient,
      firstNameChk,
      lastNameChk,
      emailChk,
      validateForm,
      invitePatient,
      cancelInvite,
      isValid,
      showalert,
      showerr,
      'open-invite-btn': openInviteBtn,
    };
  },
};
</script>
