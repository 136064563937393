<template>
  <div id="collapse-C" class="collapse show">
    <div ref="credentialing-container" class="card-body position-relative">
      <div class="row">
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col">
              <h2>State Credentialing Resources</h2>
              <p>Select a state below to see available resources</p>
              <form class="form-floating">
                <select
                  id="state-dropdown"
                  v-model="selectedStateId"
                  class="form-select my-3"
                >
                  <option
                    v-for="state in states"
                    :id="`option-${state.id}`"
                    :key="state.id"
                    :value="state.id"
                  >
                    {{ state.name }}
                  </option>
                </select>
                <label for="floatingSelect">Select a State</label>
              </form>
              <div class="list-group my-3">
                <a
                  v-for="link in stateLinks"
                  :id="`state-link-${link.id}`"
                  :key="link.id"
                  :href="link.url"
                  
                  class="list-group-item list-group-item-action oLink"
                  >{{ link.description }}</a
                >
              </div>
              <credentialing-promo type="credentialing" />
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <h2>General Resources</h2>
          <div class="list-group my-3">
            <a
              v-for="topic in topics"
              :id="`general-link-${topic.topicId}`"
              :key="topic.topicId"
              :href="topic.url"
              
              class="list-group-item list-group-item-action oLink"
              >{{ topic.description }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, inject, ref, computed } from 'vue';
import CredentialingPromo from '../../shared/components/CredentialingPromo.vue';

export default {
  components: {
    CredentialingPromo,
  },
  setup() {
    const loading = inject('$loading');
    const dataApi = inject('api').generalDataApi();

    const states = ref([]);
    const topics = ref([]);
    const credentialingContainer = ref(null);
    onMounted(async () => {
      const loader = loading.show({
        container: credentialingContainer.value,
        isFullPage: false,
      });
      let response = await dataApi.credentialingStates();
      states.value = response.data;
      response = await dataApi.credentialingTopics();
      topics.value = response.data;
      loader.hide();
    });

    const selectedStateId = ref('IN');

    const stateLinks = computed(() => {
      const selectedState = states.value.find(
        (state) => state.id == selectedStateId.value
      );
      if (!selectedState) return [];
      return selectedState.links;
    });

    return {
      'credentialing-container': credentialingContainer,
      states,
      topics,
      selectedStateId,
      stateLinks,
    };
  },
};
</script>
